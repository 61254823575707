import images from '../assets';

const data = {
  classes: [
    { id: 0, name: "titan", display: "Titan", img: images.titan },
    { id: 1, name: "warlock", display: "Warlock", img: images.warlock },
    { id: 2, name: "hunter", display: "Hunter", img: images.hunter }
  ],
  subclasses: [
    { id: 0, name: "void", display: "Void", img: images.grape },
    { id: 1, name: "solar", display: "Solar", img: images.solar },
    { id: 2, name: "arc", display: "Arc", img: images.arc },
    { id: 3, name: "stasis", display: "Stasis", img: images.stasis },
    { id: 4, name: "strand", display: "Strand", img: images.strand },
  ],
  // ID is: number within class/subclass, subclass, class
  // sentinel shield is the first void titan super. void is 0, titan is 0. ID will be 100
  supers: [
    //titan
    { id: 100, name: "sentinel_shield", display: "Sentinel Shield", class: "titan", subclass: "void", img: images.sentinel_shield },
    { id: 200, name: "ward_of_dawn", display: "Ward of Dawn", class: "titan", subclass: "void", img: images.ward_of_dawn },
    { id: 110, name: "burining_maul", display: "Burning Maul", class: "titan", subclass: "solar", img: images.burning_maul },
    { id: 210, name: "hammer_of_sol", display: "Hammer of Sol", class: "titan", subclass: "solar", img: images.hammer_of_sol },
    { id: 120, name: "fists_of_havoc", display: "Fists of Havoc", class: "titan", subclass: "arc", img: images.fists_of_havoc },
    { id: 220, name: "thundercrash", display: "Thundercrash", class: "titan", subclass: "arc", img: images.thundercrash },
    { id: 130, name: "glacial_quake", display: "Glacial Quake", class: "titan", subclass: "stasis", img: images.glacial_quake },
    { id: 140, name: "bladefury", display: "Bladefury", class: "titan", subclass: "strand", img: images.bladefury },

    //warlock
    { id: 101, name: "nova_bomb_cataclysm", display: "Nova Bomb: Cataclysm", class: "warlock", subclass: "void", img: images.nova_bomb_cataclysm },
    { id: 201, name: "nova_bomb_vortex", display: "Nova Bomb: Vortex", class: "warlock", subclass: "void", img: images.nova_bomb_vortex },
    { id: 301, name: "nova_warp", display: "Nova Warp", class: "warlock", subclass: "void", img: images.nova_warp },
    { id: 111, name: "daybreak", display: "Daybreak", class: "warlock", subclass: "solar", img: images.daybreak },
    { id: 211, name: "well_of_radiance", display: "Well of Radiance", class: "warlock", subclass: "solar", img: images.well_of_radiance },
    { id: 121, name: "chaos_reach", display: "Chaos Reach", class: "warlock", subclass: "arc", img: images.chaos_reach },
    { id: 221, name: "stormtrance", display: "Stormtrance", class: "warlock", subclass: "arc", img: images.stormtrance },
    { id: 131, name: "winters_wrath", display: "Winter's Wrath", class: "warlock", subclass: "stasis", img: images.winters_wrath },
    { id: 141, name: "needlestorm", display: "Needlestorm", class: "warlock", subclass: "strand", img: images.needlestorm },

    //hunter
    { id: 102, name: "shadowshot_deadfall", display: "Shadowshot: Deadfall", class: "hunter", subclass: "void", img: images.shadowshot_deadfall },
    { id: 202, name: "shadowshot_mobius_quiver", display: "Shadowshot: Mobius Quiver", class: "hunter", subclass: "void", img: images.shadowshot_mobius_quiver },
    { id: 302, name: "spectral_blades", display: "Spectral Blades", class: "hunter", subclass: "void", img: images.spectral_blades },
    { id: 112, name: "blade_barrage", display: "Blade Barrage", class: "hunter", subclass: "solar", img: images.blade_barrage },
    { id: 212, name: "golden_gun_deadshot", display: "Golden Gun: Deadshot", class: "hunter", subclass: "solar", img: images.golden_gun_deadshot },
    { id: 312, name: "golden_gun_marksman", display: "Golden Gun: Marksman", class: "hunter", subclass: "solar", img: images.golden_gun_marksman },
    { id: 122, name: "arc_staff", display: "Arc Staff", class: "hunter", subclass: "arc", img: images.arc_staff },
    { id: 222, name: "gathering_storm", display: "Gathering Storm", class: "hunter", subclass: "arc", img: images.gathering_storm },
    { id: 132, name: "silence_and_squall", display: "Silence And Squall", class: "hunter", subclass: "stasis", img: images.silence_and_squall },
    { id: 142, name: "silkstrike", display: "Silkstrike", class: "hunter", subclass: "strand", img: images.silkstrike },
  ],

  melees: [
    //titan
    { id: 100, name: "shield_bash", display: "Shield Bash", class: "titan", subclass: "void", img: images.shield_bash },
    { id: 200, name: "shield_throw", display: "Shield Throw", class: "titan", subclass: "void", img: images.shield_throw },
    { id: 110, name: "hammer_strike", display: "Hammer Strike", class: "titan", subclass: "solar", img: images.hammer_strike },
    { id: 210, name: "throwing_hammer", display: "Throwing Hammer", class: "titan", subclass: "solar", img: images.throwing_hammer },
    { id: 120, name: "ballistic_slam", display: "Ballistic Slam", class: "titan", subclass: "arc", img: images.ballistic_slam },
    { id: 220, name: "seismic_strike", display: "Seismic Strike", class: "titan", subclass: "arc", img: images.seismic_strike },
    { id: 320, name: "thunderclap", display: "Thunderclap", class: "titan", subclass: "arc", img: images.thunderclap },
    { id: 130, name: "shiver_strike", display: "Shiver Strike", class: "titan", subclass: "stasis", img: images.shiver_strike },
    { id: 140, name: "frenzied_blade", display: "Frenzied Blade", class: "titan", subclass: "strand", img: images.frenzied_blade },

    //warlock
    { id: 101, name: "pocket_singularity", display: "Pocket Singularity", class: "warlock", subclass: "void", img: images.pocket_singularity },
    { id: 111, name: "celestial_fire", display: "Celestial Fire", class: "warlock", subclass: "solar", img: images.celestial_fire },
    { id: 211, name: "incinerator_snap", display: "Incinerator Snap", class: "warlock", subclass: "solar", img: images.incinerator_snap },
    { id: 121, name: "ball_lightning", display: "Ball Lightning", class: "warlock", subclass: "arc", img: images.ball_lightning },
    { id: 221, name: "chain_lightning", display: "Chain Lightning", class: "warlock", subclass: "arc", img: images.chain_lightning },
    { id: 131, name: "penumbral_blast", display: "Penumbral Blast", class: "warlock", subclass: "stasis", img: images.penumbral_blast },
    { id: 141, name: "arcane_needle", display: "Arcane Needle", class: "warlock", subclass: "strand", img: images.arcane_needle },

    //hunter
    { id: 102, name: "snare_bomb", display: "Snare Bomb", class: "hunter", subclass: "void", img: images.snare_bomb },
    { id: 112, name: "knife_trick", display: "Knife Trick", class: "hunter", subclass: "solar", img: images.knife_trick },
    { id: 212, name: "lightweight_knife", display: "Lightweight Knife", class: "hunter", subclass: "solar", img: images.lightweight_knife },
    { id: 312, name: "proximity_explosive_knife", display: "Proximity Explosive Knife", class: "hunter", subclass: "solar", img: images.proximity_explosive_knife },
    { id: 412, name: "weighted_throwing_knife", display: "Weighted Throwing Knife", class: "hunter", subclass: "solar", img: images.weighted_throwing_knife },
    { id: 122, name: "combination_blow", display: "Combination Blow", class: "hunter", subclass: "arc", img: images.combination_blows },
    { id: 222, name: "disorienting_blow", display: "Disorienting Blow", class: "hunter", subclass: "arc", img: images.disorienting_blows },
    { id: 132, name: "withering_blade", display: "Withering Blade", class: "hunter", subclass: "stasis", img: images.withering_blade },
    { id: 142, name: "threaded_spike", display: "Threaded Spike", class: "hunter", subclass: "strand", img: images.threaded_spike },

  ],

  //ID is decided by number, subclass number
  grenades: [
    //void
    { id: 10, name: "axion_bolt_grenade", display: "Axion Bolt", subclass: "void", img: images.axion_bolt_grenade },
    { id: 20, name: "magnetic_grenade", display: "Magnetic Grenade", subclass: "void", img: images.magnetic_grenade },
    { id: 30, name: "scatter_grenade", display: "Scatter Grenade", subclass: "void", img: images.scatter_grenade },
    { id: 40, name: "supressor_grenade", display: "Supressor Grenade", subclass: "void", img: images.suppressor_grenade },
    { id: 50, name: "void_spike_grenade", display: "Void Spike", subclass: "void", img: images.void_spike_grenade },
    { id: 60, name: "void_wall_grenade", display: "Void Wall", subclass: "void", img: images.void_wall_grenade },
    { id: 70, name: "vortex_grenade", display: "Vortex Grenade", subclass: "void", img: images.vortex_grenade },

    //solar
    { id: 11, name: "firebolt_grenade", display: "Firebolt Grenade", subclass: "solar", img: images.firebolt_grenade },
    { id: 21, name: "fusion_grenade", display: "Fusion Grenade", subclass: "solar", img: images.fusion_grenade },
    { id: 31, name: "healing_grenade", display: "Healing Grenade", subclass: "solar", img: images.healing_grenade },
    { id: 41, name: "incindiary_grenade", display: "Incindiary Grenade", subclass: "solar", img: images.incindiary_grenade },
    { id: 51, name: "solar_grenade", display: "Solar Grenade", subclass: "solar", img: images.solar_grenade },
    { id: 61, name: "swarm_grenade", display: "Swarm Grenade", subclass: "solar", img: images.swarm_grenade },
    { id: 71, name: "thermite_grenade", display: "Thermite Grenade", subclass: "solar", img: images.thermite_grenade },
    { id: 81, name: "tripmine_grenade", display: "Tripmine Grenade", subclass: "solar", img: images.tripmine_grenade },

    //arc
    { id: 12, name: "arcbolt_grenade", display: "Arcbolt Grenade", subclass: "arc", img: images.arcbolt_grenade },
    { id: 22, name: "flashbang_grenade", display: "Flashbang Grenade", subclass: "arc", img: images.flashbang_grenade },
    { id: 32, name: "flux_grenade", display: "Flux Grenade", subclass: "arc", img: images.flux_grenade },
    { id: 42, name: "lightning_grenade", display: "Lightning Grenade", subclass: "arc", img: images.lightning_grenade },
    { id: 52, name: "pulse_grenade", display: "Pulse Grenade", subclass: "arc", img: images.pulse_grenade },
    { id: 62, name: "skip_grenade", display: "Skip Grenade", subclass: "arc", img: images.skip_grenade },
    { id: 72, name: "storm_grenade", display: "Storm Grenade", subclass: "arc", img: images.storm_grenade },

    //stasis
    { id: 13, name: "coldsnap_grenade", display: "Coldsnap Grenade", subclass: "stasis", img: images.coldsnap_grenade },
    { id: 23, name: "duskfield_grenade", display: "Duskfield Grenade", subclass: "stasis", img: images.duskfield_grenade },
    { id: 33, name: "glacier_grenade", display: "Glacier Grenade", subclass: "stasis", img: images.glacier_grenade },

    //strand
    { id: 14, name: "grapple_grenade", display: "Grapple Grenade", subclass: "strand", img: images.grapple_grenade },
    { id: 24, name: "shackle_grenade", display: "Shackle Grenade", subclass: "strand", img: images.shackle_grenade },
    { id: 34, name: "threadling_grenade", display: "Threadling Grenade", subclass: "strand", img: images.threadling_grenade },
  ],

  // ID is: number within class/subclass, subclass, class
  classabil: [
    //titan
    { id: 100, name: "rally_barricade_voi", display: "Rally Barricade", class: "titan", subclass: "void", img: images.rally_barricade_voi },
    { id: 200, name: "towering_barricade_voi", display: "Towering Barricade", class: "titan", subclass: "void", img: images.towering_barricade_voi },
    { id: 110, name: "rally_barricade_sol", display: "Rally Barricade", class: "titan", subclass: "solar", img: images.rally_barricade_sol },
    { id: 210, name: "towering_barricade_sol", display: "Towering Barricade", class: "titan", subclass: "solar", img: images.towering_barricade_sol },
    { id: 120, name: "rally_barricade_arc", display: "Rally Barricade", class: "titan", subclass: "arc", img: images.rally_barricade_arc },
    { id: 220, name: "towering_barricade_arc", display: "Towering Barricade", class: "titan", subclass: "arc", img: images.towering_barricade_arc },
    { id: 320, name: "thruster", display: "Thruster", class: "titan", subclass: "arc", img: images.thruster },
    { id: 130, name: "rally_barricade_sta", display: "Rally Barricade", class: "titan", subclass: "stasis", img: images.rally_barricade_sta },
    { id: 230, name: "towering_barricade_sta", display: "Towering Barricade", class: "titan", subclass: "stasis", img: images.towering_barricade_sta },
    { id: 140, name: "rally_barricade_str", display: "Rally Barricade", class: "titan", subclass: "strand", img: images.rally_barricade_str },
    { id: 240, name: "towering_barricade_str", display: "Towering Barricade", class: "titan", subclass: "strand", img: images.towering_barricade_str },

    //warlock
    { id: 101, name: "empowering_rift_voi", display: "Empowering Rift", class: "warlock", subclass: "void", img: images.empowering_rift_voi },
    { id: 201, name: "healing_rift_voi", display: "Healing Rift", class: "warlock", subclass: "void", img: images.healing_rift_voi },
    { id: 111, name: "empowering_rift_sol", display: "Empowering Rift", class: "warlock", subclass: "solar", img: images.empowering_rift_sol },
    { id: 211, name: "healing_rift_sol", display: "Healing Rift", class: "warlock", subclass: "solar", img: images.healing_rift_sol },
    { id: 311, name: "phoenix_dive", display: "Phoenix Dive", class: "warlock", subclass: "solar", img: images.phoenix_dive },
    { id: 121, name: "empowering_rift_arc", display: "Empowering Rift", class: "warlock", subclass: "arc", img: images.empowering_rift_arc },
    { id: 221, name: "healing_rift_arc", display: "Healing Rift", class: "warlock", subclass: "arc", img: images.healing_rift_arc },
    { id: 131, name: "empowering_rift_sta", display: "Empowering Rift", class: "warlock", subclass: "stasis", img: images.empowering_rift_sta },
    { id: 231, name: "healing_rift_sta", display: "Healing Rift", class: "warlock", subclass: "stasis", img: images.healing_rift_sta },
    { id: 141, name: "empowering_rift_str", display: "Empowering Rift", class: "warlock", subclass: "strand", img: images.empowering_rift_str },
    { id: 241, name: "healing_rift_str", display: "Healing Rift", class: "warlock", subclass: "strand", img: images.healing_rift_str },

    //hunter
    { id: 102, name: "gamblers_dodge_voi", display: "Gambler's Dodge", class: "hunter", subclass: "void", img: images.gamblers_dodge_voi },
    { id: 202, name: "marksman_dodge_voi", display: "Marksman Dodge", class: "hunter", subclass: "void", img: images.marksman_dodge_voi },
    { id: 112, name: "gamblers_dodge_sol", display: "Gambler's Dodge", class: "hunter", subclass: "solar", img: images.gamblers_dodge_sol },
    { id: 212, name: "marksman_dodge_sol", display: "Marksman Dodge", class: "hunter", subclass: "solar", img: images.marksman_dodge_sol },
    { id: 312, name: "acrobat_dodge", display: "Acrobat Dodge", class: "hunter", subclass: "solar", img: images.acrobat_dodge },
    { id: 122, name: "gamblers_dodge_arc", display: "Gambler's Dodge", class: "hunter", subclass: "arc", img: images.gamblers_dodge_arc },
    { id: 222, name: "marksman_dodge_arc", display: "Marksman Dodge", class: "hunter", subclass: "arc", img: images.marksman_dodge_arc },
    { id: 132, name: "gamblers_dodge_sta", display: "Gambler's Dodge", class: "hunter", subclass: "stasis", img: images.gamblers_dodge_sta },
    { id: 232, name: "marksman_dodge_sta", display: "Marksman Dodge", class: "hunter", subclass: "stasis", img: images.marksman_dodge_sta },
    { id: 142, name: "gamblers_dodge_str", display: "Gambler's Dodge", class: "hunter", subclass: "strand", img: images.gamblers_dodge_str },
    { id: 242, name: "marksman_dodge_str", display: "Marksman Dodge", class: "hunter", subclass: "strand", img: images.marksman_dodge_str },
  ],

  // ID is: number within class/subclass, subclass, class
  aspects: [
    //titan
    { id: 100, name: "bastion", display: "Bastion", class: "titan", subclass: "void", fragslots: 1, img: images.bastion },
    { id: 200, name: "controlled_demolition", display: "Controlled Demolition", class: "titan", subclass: "void", fragslots: 2, img: images.controlled_demolition },
    { id: 300, name: "offensive_bulwark", display: "Offensive Bulwark", class: "titan", subclass: "void", fragslots: 2, img: images.offensive_bulwark },
    { id: 110, name: "consecration", display: "Consecration", class: "titan", subclass: "solar", fragslots: 2, img: images.consecration },
    { id: 210, name: "roaring_flames", display: "Roaring Flames", class: "titan", subclass: "solar", fragslots: 2, img: images.roaring_flames },
    { id: 310, name: "sol_invictus", display: "Sol Invictus", class: "titan", subclass: "solar", fragslots: 2, img: images.sol_invictus },
    { id: 120, name: "juggernaut", display: "Juggernaut", class: "titan", subclass: "arc", fragslots: 1, img: images.juggernaut },
    { id: 220, name: "knockout", display: "Knockout", class: "titan", subclass: "arc", fragslots: 2, img: images.knockout },
    { id: 320, name: "touch_of_thunder", display: "Touch of Thunder", class: "titan", subclass: "arc", fragslots: 2, img: images.touch_of_thunder },
    { id: 130, name: "cryoclasm", display: "Cryoclasm", class: "titan", subclass: "stasis", fragslots: 2, img: images.cryoclasm },
    { id: 230, name: "diamond_lance", display: "Diamond Lance", class: "titan", subclass: "stasis", fragslots: 3, img: images.diamond_lance },
    { id: 330, name: "howl_of_the_storm", display: "Howl of the Storm", class: "titan", subclass: "stasis", fragslots: 2, img: images.howl_of_the_storm },
    { id: 430, name: "tectonic_harvest", display: "Tectonic Harvest", class: "titan", subclass: "stasis", fragslots: 2, img: images.tectonic_harvest },
    { id: 140, name: "drengrs_lash", display: "Drengr's Lash", class: "titan", subclass: "strand", fragslots: 2, img: images.drengrs_lash },
    { id: 240, name: "into_the_fray", display: "Into the Fray", class: "titan", subclass: "strand", fragslots: 2, img: images.into_the_fray },

    //warlock
    { id: 101, name: "chaos_accelerant", display: "Chaos Accelerant", class: "warlock", subclass: "void", fragslots: 1, img: images.chaos_accelerant },
    { id: 201, name: "child_of_the_old_gods", display: "Child of the Old Gods", class: "warlock", subclass: "void", fragslots: 2, img: images.child_of_the_old_gods },
    { id: 301, name: "feed_the_void", display: "Feed the Void", class: "warlock", subclass: "void", fragslots: 2, img: images.feed_the_void },
    { id: 111, name: "heat_rises", display: "Heat Rises", class: "warlock", subclass: "solar", fragslots: 2, img: images.heat_rises },
    { id: 211, name: "icarus_dash", display: "Icarus Dash", class: "warlock", subclass: "solar", fragslots: 2, img: images.icarus_dash },
    { id: 311, name: "touch_of_flame", display: "Touch of Flame", class: "warlock", subclass: "solar", fragslots: 2, img: images.touch_of_flame },
    { id: 121, name: "arc_soul", display: "Arc Soul", class: "warlock", subclass: "arc", fragslots: 2, img: images.arc_soul },
    { id: 221, name: "electrostatic_mind", display: "Electrostatic Mind", class: "warlock", subclass: "arc", fragslots: 2, img: images.electrostatic_mind },
    { id: 321, name: "lightning_surge", display: "Lightning Surge", class: "warlock", subclass: "arc", fragslots: 2, img: images.lightning_surge },
    { id: 131, name: "bleak_watcher", display: "Bleak Watcher", class: "warlock", subclass: "stasis", fragslots: 2, img: images.bleak_watcher },
    { id: 231, name: "frostpulse", display: "Frostpulse", class: "warlock", subclass: "stasis", fragslots: 2, img: images.frostpulse },
    { id: 331, name: "glacial_harvest", display: "Glacial Harvest", class: "warlock", subclass: "stasis", fragslots: 2, img: images.glacial_harvest },
    { id: 431, name: "iceflare_bolts", display: "Iceflare Bolts", class: "warlock", subclass: "stasis", fragslots: 2, img: images.iceflare_bolts },
    { id: 141, name: "mindspun_invocation", display: "Mindspun Invocation", class: "warlock", subclass: "strand", fragslots: 2, img: images.mindspun_invocation },
    { id: 241, name: "weavers_call", display: "Weaver's Call", class: "warlock", subclass: "strand", fragslots: 2, img: images.weavers_call },

    //hunter
    { id: 102, name: "stylish_executioner", display: "Stylish Executioner", class: "hunter", subclass: "void", fragslots: 2, img: images.stylish_executioner },
    { id: 202, name: "trappers_ambush", display: "Trapper's Ambush", class: "hunter", subclass: "void", fragslots: 1, img: images.trappers_ambush },
    { id: 302, name: "vanishing_step", display: "Vanishing Step", class: "hunter", subclass: "void", fragslots: 2, img: images.vanishing_step },
    { id: 112, name: "gunpowder_gamble", display: "Gunpowder Gamble", class: "hunter", subclass: "solar", fragslots: 1, img: images.gunpowder_gamble },
    { id: 212, name: "knock_em_down", display: "Knock Em Down", class: "hunter", subclass: "solar", fragslots: 2, img: images.knock_em_down },
    { id: 312, name: "on_your_mark", display: "On Your Mark", class: "hunter", subclass: "solar", fragslots: 3, img: images.on_your_mark },
    { id: 122, name: "flow_state", display: "Flow State", class: "hunter", subclass: "arc", fragslots: 2, img: images.flow_state },
    { id: 222, name: "lethal_current", display: "Lethal Current", class: "hunter", subclass: "arc", fragslots: 2, img: images.lethal_current },
    { id: 322, name: "tempest_strike", display: "Tempest Strike", class: "hunter", subclass: "arc", fragslots: 2, img: images.tempest_strike },
    { id: 132, name: "grim_harvest", display: "Grim Harvest", class: "hunter", subclass: "stasis", fragslots: 3, img: images.grim_harvest },
    { id: 232, name: "shatterdive", display: "Shatterdive", class: "hunter", subclass: "stasis", fragslots: 1, img: images.shatterdive },
    { id: 332, name: "touch_of_winter", display: "Touch of Winter", class: "hunter", subclass: "stasis", fragslots: 2, img: images.touch_of_winter },
    { id: 432, name: "winters_shroud", display: "Winter's Shroud", class: "hunter", subclass: "stasis", fragslots: 2, img: images.winters_shroud },
    { id: 142, name: "ensearing_slam", display: "Ensearing Slam", class: "hunter", subclass: "strand", fragslots: 2, img: images.ensearing_slam },
    { id: 242, name: "widows_silk", display: "Widow's Silk", class: "hunter", subclass: "strand", fragslots: 2, img: images.widows_silk },
  ],

  //ID is chosen by subclass, number in subclass
  fragments: [
    //test
    //void
    { id: 10, name: "echo_of_cessation", display: "Echo of Cessation", subclass: "void", img: images.echo_of_cessation },
    { id: 20, name: "echo_of_dialation", display: "Echo of Dialation", subclass: "void", img: images.echo_of_dialation },
    { id: 30, name: "echo_of_domineering", display: "Echo of Domineering", subclass: "void", img: images.echo_of_domineering },
    { id: 40, name: "echo_of_exchange", display: "Echo of Exchange", subclass: "void", img: images.echo_of_exchange },
    { id: 50, name: "echo_of_expulsion", display: "Echo of Expulsion", subclass: "void", img: images.echo_of_expulsion },
    { id: 60, name: "echo_of_harvest", display: "Echo of Harvest", subclass: "void", img: images.echo_of_harvest },
    { id: 70, name: "echo_of_instability", display: "Echo of Instability", subclass: "void", img: images.echo_of_instability },
    { id: 80, name: "echo_of_leeching", display: "Echo of Leeching", subclass: "void", img: images.echo_of_leeching },
    { id: 90, name: "echo_of_obscurity", display: "Echo of Obscurity", subclass: "void", img: images.echo_of_obscurity },
    { id: 100, name: "echo_of_persistance", display: "Echo of Persistance", subclass: "void", img: images.echo_of_persistance },
    { id: 110, name: "echo_of_provision", display: "Echo of Provision", subclass: "void", img: images.echo_of_provision },
    { id: 120, name: "echo_of_remnants", display: "Echo of Remnants", subclass: "void", img: images.echo_of_remnants },
    { id: 130, name: "echo_of_reprisal", display: "Echo of Reprisal", subclass: "void", img: images.echo_of_reprisal },
    { id: 140, name: "echo_of_starvation", display: "Echo of Starvation", subclass: "void", img: images.echo_of_starvation },
    { id: 150, name: "echo_of_undermining", display: "Echo of Undermining", subclass: "void", img: images.echo_of_undermining },
    { id: 160, name: "echo_of_vigilance", display: "Echo of Vigilance", subclass: "void", img: images.echo_of_vigilance },

    //solar
    { id: 11, name: "ember_of_ashes", display: "Ember of Ashes", subclass: "solar", img: images.ember_of_ashes },
    { id: 21, name: "ember_of_benevolence", display: "Ember of Benevolence", subclass: "solar", img: images.ember_of_benevolence },
    { id: 31, name: "ember_of_beams", display: "Ember of Beams", subclass: "solar", img: images.ember_of_beams },
    { id: 41, name: "ember_of_blistering", display: "Ember of Blistering", subclass: "solar", img: images.ember_of_blistering },
    { id: 51, name: "ember_of_char", display: "Ember of Char", subclass: "solar", img: images.ember_of_char },
    { id: 61, name: "ember_of_combustion", display: "Ember of Combustion", subclass: "solar", img: images.ember_of_combustion },
    { id: 71, name: "ember_of_empyrian", display: "Ember of Empyrian", subclass: "solar", img: images.ember_of_empyrian },
    { id: 81, name: "ember_of_eruption", display: "Ember of Eruption", subclass: "solar", img: images.ember_of_eruption },
    { id: 91, name: "ember_of_mercy", display: "Ember of Mercy", subclass: "solar", img: images.ember_of_mercy },
    { id: 101, name: "ember_of_resolve", display: "Ember of Resolve", subclass: "solar", img: images.ember_of_resolve },
    { id: 111, name: "ember_of_searing", display: "Ember of Searing", subclass: "solar", img: images.ember_of_searing },
    { id: 121, name: "ember_of_singeing", display: "Ember of Singeing", subclass: "solar", img: images.ember_of_singeing },
    { id: 131, name: "ember_of_solace", display: "Ember of Solace", subclass: "solar", img: images.ember_of_solace },
    { id: 141, name: "ember_of_tempering", display: "Ember of Tempering", subclass: "solar", img: images.ember_of_tempering },
    { id: 151, name: "ember_of_torches", display: "Ember of Torches", subclass: "solar", img: images.ember_of_torches },
    { id: 161, name: "ember_of_wonder", display: "Ember of Wonder", subclass: "solar", img: images.ember_of_wonder },

    //arc
    { id: 12, name: "spark_of_amplitude", display: "Spark of Amplitude", subclass: "arc", img: images.spark_of_amplitude },
    { id: 22, name: "spark_of_beacons", display: "Spark of Beacons", subclass: "arc", img: images.spark_of_beacons },
    { id: 32, name: "spark_of_brilliance", display: "Spark of Brilliance", subclass: "arc", img: images.spark_of_brilliance },
    { id: 42, name: "spark_of_discharge", display: "Spark of Discharge", subclass: "arc", img: images.spark_of_discharge },
    { id: 52, name: "spark_of_feedback", display: "Spark of Feedback", subclass: "arc", img: images.spark_of_feedback },
    { id: 62, name: "spark_of_focus", display: "Spark of Focus", subclass: "arc", img: images.spark_of_focus },
    { id: 72, name: "spark_of_frequency", display: "Spark of Frequency", subclass: "arc", img: images.spark_of_frequency },
    { id: 82, name: "spark_of_haste", display: "Spark of Haste", subclass: "arc", img: images.spark_of_haste },
    { id: 92, name: "spark_of_instinct", display: "Spark of Instinct", subclass: "arc", img: images.spark_of_instinct },
    { id: 102, name: "spark_of_ions", display: "Spark of Ions", subclass: "arc", img: images.spark_of_ions },
    { id: 112, name: "spark_of_magnitude", display: "Spark of Magnitude", subclass: "arc", img: images.spark_of_magnitude },
    { id: 122, name: "spark_of_momentum", display: "Spark of Momentum", subclass: "arc", img: images.spark_of_momentum },
    { id: 132, name: "spark_of_recharge", display: "Spark of Recharge", subclass: "arc", img: images.spark_of_recharge },
    { id: 142, name: "spark_of_resistance", display: "Spark of Resistance", subclass: "arc", img: images.spark_of_resistance },
    { id: 152, name: "spark_of_shock", display: "Spark of Shock", subclass: "arc", img: images.spark_of_shock },
    { id: 162, name: "spark_of_volts", display: "Spark of Volts", subclass: "arc", img: images.spark_of_volts },

    //stasis
    { id: 13, name: "whisper_of_bonds", display: "Whisper of Bonds", subclass: "stasis", img: images.whisper_of_bonds },
    { id: 23, name: "whisper_of_chains", display: "Whisper of Chains", subclass: "stasis", img: images.whisper_of_chains },
    { id: 33, name: "whisper_of_conduction", display: "Whisper of Conduction", subclass: "stasis", img: images.whisper_of_conduction },
    { id: 43, name: "whisper_of_durance", display: "Whisper of Durance", subclass: "stasis", img: images.whisper_of_durance },
    { id: 53, name: "whisper_of_fissures", display: "Whisper of Fissures", subclass: "stasis", img: images.whisper_of_fissures },
    { id: 63, name: "whisper_of_fractures", display: "Whisper of Fractures", subclass: "stasis", img: images.whisper_of_fractures },
    { id: 73, name: "whisper_of_hedrons", display: "Whisper of Hedrons", subclass: "stasis", img: images.whisper_of_hedrons },
    { id: 83, name: "whisper_of_hunger", display: "Whisper of Hunger", subclass: "stasis", img: images.whisper_of_hunger },
    { id: 93, name: "whisper_of_impetus", display: "Whisper of Impetus", subclass: "stasis", img: images.whisper_of_impetus },
    { id: 103, name: "whisper_of_refraction", display: "Whisper of Refraction", subclass: "stasis", img: images.whisper_of_refraction },
    { id: 113, name: "whisper_of_rending", display: "Whisper of Rending", subclass: "stasis", img: images.whisper_of_rending },
    { id: 123, name: "whisper_of_rime", display: "Whisper of Rime", subclass: "stasis", img: images.whisper_of_rime },
    { id: 133, name: "whisper_of_shards", display: "Whisper of Shards", subclass: "stasis", img: images.whisper_of_shards },
    { id: 143, name: "whisper_of_torment", display: "Whisper of Torment", subclass: "stasis", img: images.whisper_of_torment },

    //strand
    { id: 14, name: "thread_of_ascent", display: "Thread of Ascent", subclass: "strand", img: images.thread_of_ascent },
    { id: 24, name: "thread_of_binding", display: "Thread of Binding", subclass: "strand", img: images.thread_of_binding },
    { id: 34, name: "thread_of_continuity", display: "Thread of Continuity", subclass: "strand", img: images.thread_of_continuity },
    { id: 44, name: "thread_of_evolution", display: "Thread of Evolution", subclass: "strand", img: images.thread_of_evolution },
    { id: 54, name: "thread_of_finality", display: "Thread of Finality", subclass: "strand", img: images.thread_of_finality },
    { id: 64, name: "thread_of_fury", display: "Thread of Fury", subclass: "strand", img: images.thread_of_fury },
    { id: 74, name: "thread_of_generation", display: "Thread of Generation", subclass: "strand", img: images.thread_of_generation },
    { id: 84, name: "thread_of_isolation", display: "Thread of Isolation", subclass: "strand", img: images.thread_of_isolation },
    { id: 94, name: "thread_of_mind", display: "Thread of Mind", subclass: "strand", img: images.thread_of_mind },
    { id: 104, name: "thread_of_propagation", display: "Thread of Propogation", subclass: "strand", img: images.thread_of_propagation },
    { id: 114, name: "thread_of_rebirth", display: "Thread of Rebirth", subclass: "strand", img: images.thread_of_rebirth },
    { id: 124, name: "thread_of_transmutation", display: "Thread of Transmutation", subclass: "strand", img: images.thread_of_transmutation },
    { id: 134, name: "thread_of_warding", display: "Thread of Warding", subclass: "strand", img: images.thread_of_warding },
    { id: 144, name: "thread_of_wisdom", display: "Thread of Wisdom", subclass: "strand", img: images.thread_of_wisdom },
  ],

  weapons: [
    { name: "ace_of_spades", display: "Ace of Spades", type: "Hand Cannon", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.ace_of_spades },
    { name: "agers_scepter", display: "Ager's Scepter", type: "Trace Rifle", ammo: "Special", energy: "Stasis", slot: "Kinetic", img: images.agers_scepter },
    { name: "anarchy", display: "Anarchy", type: "Heavy Grenade Launcher", ammo: "Heavy", energy: "Arc", slot: "Heavy", img: images.anarchy },
    { name: "arbalest", display: "Arbalest", type: "Linear Fusion Rifle", ammo: "Special", energy: "Kinetic", slot: "Kinetic", img: images.arbalest },
    { name: "bad_juju", display: "Bad Juju", type: "Pulse Rifle", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.bad_juju },
    { name: "bastion_wea", display: "Bastion", type: "Fusion Rifle", ammo: "Special", energy: "Kinetic", slot: "Kinetic", img: images.bastion_wea },
    { name: "black_talon", display: "Black Talon", type: "Sword", ammo: "Heavy", energy: "Void", slot: "Heavy", img: images.black_talon },
    { name: "borealis", display: "Borealis", type: "Sniper Rifle", ammo: "Special", energy: "Void", slot: "Energy", img: images.borealis },
    { name: "cerberus_plus1", display: "Cerberus+1", type: "Auto Rifle", ammo: "Primary", energy: "Solar", slot: "Energy", img: images.cerberus_plus1 },
    { name: "cloudstrike", display: "Cloudstrike", type: "Sniper Rifle", ammo: "Special", energy: "Arc", slot: "Energy", img: images.cloudstrike },

    { name: "coldheart", display: "Coldheart", type: "Trace Rifle", ammo: "Special", energy: "Arc", slot: "Energy", img: images.coldheart },
    { name: "collective_obligation", display: "Collective Obligation", type: "Pulse Rifle", ammo: "Primary", energy: "Void", slot: "Energy", img: images.collective_obligation },
    { name: "conditional_finality", display: "Conditional Finality", type: "Shotgun", ammo: "Special", energy: "Stasis", slot: "Kinetic", img: images.conditional_finality },
    { name: "crimson", display: "Crimson", type: "Hand Cannon", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.crimson },
    { name: "cryothesisa_77k", display: "Cryothesisa 77K", type: "Sidearm", ammo: "Primary", energy: "Stasis", slot: "Kinetic", img: images.cryothesisa_77k },
    { name: "darci", display: "D.A.R.C.I.", type: "Sniper Rifle", ammo: "Heavy", energy: "Arc", slot: "Heavy", img: images.darci },
    { name: "dead_mans_tale", display: "Dead Man's Tale", type: "Scout Rifle", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.dead_mans_tale },
    { name: "dead_messenger", display: "Dead Messenger", type: "Grenade Launcher", ammo: "Special", energy: "Void", slot: "Energy", img: images.dead_messenger },
    { name: "deathbringer", display: "Deathbringer", type: "Rocket Launcher", ammo: "Heavy", energy: "Void", slot: "Heavy", img: images.deathbringer },
    { name: "delicate_tomb", display: "Delicate Tomb", type: "Fusion Rifle", ammo: "Special", energy: "Arc", slot: "Energy", img: images.delicate_tomb },

    { name: "deterministic_chaos", display: "Deterministic Chaos", type: "Machine Gun", ammo: "Heavy", energy: "Void", slot: "Heavy", img: images.deterministic_chaos },
    { name: "devils_ruin", display: "Devil's Ruin", type: "Sidearm", ammo: "Primary", energy: "Solar", slot: "Energy", img: images.devils_ruin },
    { name: "divinity", display: "Divinity", type: "Trace Rifle", ammo: "Special", energy: "Arc", slot: "Energy", img: images.divinity },
    { name: "duality", display: "Duality", type: "Shotgun", ammo: "Special", energy: "Solar", slot: "Energy", img: images.duality },
    { name: "edge_of_action", display: "Edge of Action", type: "Glaive", ammo: "Special", energy: "Void", slot: "Energy", img: images.edge_of_action },
    { name: "edge_of_concurrance", display: "Edge of Concurrance", type: "Glaive", ammo: "Special", energy: "Arc", slot: "Energy", img: images.edge_of_concurrance },
    { name: "edge_of_intent", display: "Edge of Intent", type: "Glaive", ammo: "Special", energy: "Solar", slot: "Energy", img: images.edge_of_intent },
    { name: "erianas_vow", display: "Eriana's Vow", type: "Hand Cannon", ammo: "Special", energy: "Solar", slot: "Energy", img: images.erianas_vow },
    { name: "eyes_of_tomorrow", display: "Eyes of Tomorrow", type: "Rocket Launcher", ammo: "Heavy", energy: "Solar", slot: "Heavy", img: images.eyes_of_tomorrow },
    { name: "fighting_lion", display: "Fighting Lion", type: "Grenade Launcher", ammo: "Primary", energy: "Void", slot: "Energy", img: images.fighting_lion },

    { name: "final_warning", display: "Final Warning", type: "Sidearm", ammo: "Primary", energy: "Strand", slot: "Kinetic", img: images.final_warning },
    { name: "forerunner", display: "Forerunner", type: "Sidearm", ammo: "Special", energy: "Kinetic", slot: "Kinetic", img: images.forerunner },
    { name: "gjallarhorn", display: "Gjallarhorn", type: "Rocket Launcher", ammo: "Heavy", energy: "Solar", slot: "Heavy", img: images.gjallarhorn },
    { name: "grand_overture", display: "Grand Overture", type: "Machine Gun", ammo: "Heavy", energy: "Arc", slot: "Heavy", img: images.grand_overture },
    { name: "graviton_lance", display: "Graviton Lance", type: "Pulse Rifle", ammo: "Primary", energy: "Void", slot: "Energy", img: images.graviton_lance },
    { name: "hard_light", display: "Hard Light", type: "Auto Rifle", ammo: "Primary", energy: "Void", slot: "Energy", img: images.hard_light },
    { name: "hawkmoon", display: "Hawkmoon", type: "Hand Cannon", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.hawkmoon },
    { name: "heartshadow", display: "Heartshadow", type: "Sword", ammo: "Heavy", energy: "Void", slot: "Heavy", img: images.heartshadow },
    { name: "heir_apparent", display: "Heir Apparent", type: "Machine Gun", ammo: "Heavy", energy: "Solar", slot: "Heavy", img: images.heir_apparent },
    { name: "hierarchy_of_needs", display: "Hierarchy of Needs", type: "Bow", ammo: "Primary", energy: "Solar", slot: "Energy", img: images.hierarchy_of_needs },

    { name: "izanagis_burden", display: "Izanagi's Burden", type: "Sniper Rifle", ammo: "Special", energy: "Kinetic", slot: "Kinetic", img: images.izanagis_burden },
    { name: "jotunn", display: "Jotunn", type: "Fusion Rifle", ammo: "Special", energy: "Solar", slot: "Energy", img: images.jotunn },
    { name: "le_monarque", display: "Le Monarque", type: "Bow", ammo: "Primary", energy: "Void", slot: "Energy", img: images.le_monarque },
    { name: "legend_of_acrius", display: "Legend of Acrius", type: "Shotgun", ammo: "Heavy", energy: "Arc", slot: "Heavy", img: images.legend_of_acrius },
    { name: "leviathans_breath", display: "Leviathan's Breath", type: "Bow", ammo: "Heavy", energy: "Void", slot: "Heavy", img: images.leviathans_breath },
    { name: "lord_of_wolves", display: "Lord of Wolves", type: "Shotgun", ammo: "Special", energy: "Solar", slot: "Energy", img: images.lord_of_wolves },
    { name: "lorentz_driver", display: "Lorentz Driver", type: "Linear Fusion Rifle", ammo: "Special", energy: "Void", slot: "Energy", img: images.lorentz_driver },
    { name: "lumina", display: "Lumina", type: "Hand Cannon", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.lumina },
    { name: "malfeasance", display: "Malfeasance", type: "Hand Cannon", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.malfeasance },
    { name: "merciless", display: "Merciless", type: "Fusion Rifle", ammo: "Special", energy: "Arc", slot: "Energy", img: images.merciless },

    { name: "mida_multi_tool", display: "MIDA Multi Tool", type: "Scout Rifle", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.mida_multi_tool },
    { name: "monte_carlo", display: "Monte Carlo", type: "Auto Rifle", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.monte_carlo },
    { name: "no_time_to_explain", display: "No Time to Explain", type: "Pulse Rifle", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.no_time_to_explain },
    { name: "one_thousand_voices", display: "One Thousand Voices", type: "Fusion Rifle", ammo: "Heavy", energy: "Solar", slot: "Heavy", img: images.one_thousand_voices },
    { name: "osteo_striga", display: "Osteo Striga", type: "Submachine Gun", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.osteo_striga },
    { name: "outbreak_perfected", display: "Outbreak Perfected", type: "Pulse Rifle", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.outbreak_perfected },
    { name: "parasite", display: "Parasite", type: "Heavy Grenade Launcher", ammo: "Heavy", energy: "Solar", slot: "Heavy", img: images.parasite },
    { name: "polaris_lance", display: "Polaris Lance", type: "Scout Rifle", ammo: "Primary", energy: "Solar", slot: "Energy", img: images.polaris_lance },
    { name: "prometheus_lens", display: "Prometheus Lens", type: "Trace Rifle", ammo: "Special", energy: "Solar", slot: "Energy", img: images.prometheus_lens },
    { name: "quicksilver_storm", display: "Quicksilver Storm", type: "Auto Rifle", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.quicksilver_storm },

    { name: "rat_king", display: "Rat King", type: "Sidearm", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.rat_king },
    { name: "revision_zero", display: "Revision Zero", type: "Pulse Rifle", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.revision_zero },
    { name: "riskrunner", display: "Riskrunner", type: "Submachine Gun", ammo: "Primary", energy: "Arc", slot: "Energy", img: images.riskrunner },
    { name: "ruinous_effigy", display: "Ruinous Effigy", type: "Trace Rifle", ammo: "Special", energy: "Void", slot: "Energy", img: images.ruinous_effigy },
    { name: "salvations_grip", display: "Salvation's Grip", type: "Heavy Grenade Launcher", ammo: "Heavy", energy: "Stasis", slot: "Heavy", img: images.salvations_grip },
    { name: "skyburners_oath", display: "Skyburner's Oath", type: "Scout Rifle", ammo: "Primary", energy: "Solar", slot: "Energy", img: images.skyburners_oath },
    { name: "sleeper_simulant", display: "Sleeper Simulant", type: "Linear Fusion Rifle", ammo: "Heavy", energy: "Solar", slot: "Heavy", img: images.sleeper_simulant },
    { name: "sturm", display: "Sturm", type: "Hand Cannon", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.sturm },
    { name: "sunshot", display: "Sunshot", type: "Hand Cannon", ammo: "Primary", energy: "Solar", slot: "Energy", img: images.sunshot },
    { name: "suros_regime", display: "Suros Regime", type: "Auto Rifle", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.suros_regime },

    { name: "sweet_business", display: "Sweet Business", type: "Auto Rifle", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.sweet_business },
    { name: "symmetry", display: "Symmetry", type: "Pulse Rifle", ammo: "Primary", energy: "Arc", slot: "Energy", img: images.symmetry },
    { name: "tarrabah", display: "Tarrabah", type: "Submachine Gun", ammo: "Primary", energy: "Solar", slot: "Energy", img: images.tarrabah },
    { name: "telesto", display: "Telesto", type: "Fusion Rifle", ammo: "Special", energy: "Void", slot: "Energy", img: images.telesto },
    { name: "the_chaperone", display: "The Chaperone", type: "Shotgun", ammo: "Special", energy: "Kinetic", slot: "Kinetic", img: images.the_chaperone },
    { name: "the_colony", display: "The Colony", type: "Heavy Grenade Launcher", ammo: "Heavy", energy: "Solar", slot: "Heavy", img: images.the_colony },
    { name: "the_fourth_horseman", display: "The Fourth Horseman", type: "Shotgun", ammo: "Special", energy: "Arc", slot: "Energy", img: images.the_fourth_horseman },
    { name: "the_huckleberry", display: "The Huckleberry", type: "Submachine Gun", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.the_huckleberry },
    { name: "the_jade_rabbit", display: "The Jade Rabbit", type: "Scout Rifle", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.the_jade_rabbit },
    { name: "the_lament", display: "The Lament", type: "Sword", ammo: "Heavy", energy: "Solar", slot: "Heavy", img: images.the_lament },

    { name: "the_last_word", display: "The Last Word", type: "Hand Cannon", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.the_last_word },
    { name: "the_manticore", display: "The Manticore", type: "Submachine Gun", ammo: "Primary", energy: "Void", slot: "Energy", img: images.the_manticore },
    { name: "the_prospector", display: "The Prospector", type: "Heavy Grenade Launcher", ammo: "Heavy", energy: "Arc", slot: "Heavy", img: images.the_prospector },
    { name: "the_queenbreaker", display: "The Queenbreaker", type: "Linear Fusion Rifle", ammo: "Heavy", energy: "Arc", slot: "Heavy", img: images.the_queenbreaker },
    { name: "the_wardcliff_coil", display: "The Wardcliff Coil", type: "Rocket Launcher", ammo: "Heavy", energy: "Arc", slot: "Heavy", img: images.the_wardcliff_coil },
    { name: "thorn", display: "Thorn", type: "Hand Cannon", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.thorn },
    { name: "thunderlord", display: "Thunderlord", type: "Machine Gun", ammo: "Heavy", energy: "Arc", slot: "Heavy", img: images.thunderlord },
    { name: "ticuus_divination", display: "Ticuu's Divination", type: "Bow", ammo: "Primary", energy: "Solar", slot: "Energy", img: images.ticuus_divination },
    { name: "tommys_matchbook", display: "Tommy's Matchbook", type: "Auto Rifle", ammo: "Primary", energy: "Solar", slot: "Energy", img: images.tommys_matchbook },
    { name: "touch_of_malice", display: "Touch of Malice", type: "Scout Rifle", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.touch_of_malice },

    { name: "tractor_cannon", display: "Tractor Cannon", type: "Shotgun", ammo: "Heavy", energy: "Void", slot: "Heavy", img: images.tractor_cannon },
    { name: "travelers_chosen", display: "Traveler's Chosen", type: "Sidearm", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.travelers_chosen },
    { name: "trespasser", display: "Trespasser", type: "Sidearm", ammo: "Primary", energy: "Arc", slot: "Energy", img: images.trespasser },
    { name: "trinity_ghoul", display: "Trinity Ghoul", type: "Bow", ammo: "Primary", energy: "Arc", slot: "Energy", img: images.trinity_ghoul },
    { name: "truth", display: "Truth", type: "Rocket Launcher", ammo: "Heavy", energy: "Void", slot: "Heavy", img: images.truth },
    { name: "two_tailed_fox", display: "Two Tailed Fox", type: "Rocket Launcher", ammo: "Heavy", energy: "Void", slot: "Heavy", img: images.two_tailed_fox },
    { name: "verglas_curve", display: "Verglas Curve", type: "Bow", ammo: "Primary", energy: "Stasis", slot: "Kinetic", img: images.verglas_curve },
    { name: "vex_mythoclast", display: "Vex Mythoclast", type: "Fusion Rifle", ammo: "Special", energy: "Solar", slot: "Energy", img: images.vex_mythoclast },
    { name: "vexcalibur", display: "Vexcalibur", type: "Glaive", ammo: "Special", energy: "Void", slot: "Energy", img: images.vexcalibur },
    { name: "vigilance_wing", display: "Vigilance Wing", type: "Pulse Rifle", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.vigilance_wing },

    { name: "wavesplitter", display: "Wavesplitter", type: "Trace Rifle", ammo: "Special", energy: "Void", slot: "Energy", img: images.wavesplitter },
    { name: "whisper_of_the_worm", display: "Whisper of the Worm", type: "Sniper Rifle", ammo: "Heavy", energy: "Solar", slot: "Heavy", img: images.whisper_of_the_worm },
    { name: "winterbite", display: "Winterbite", type: "Glaive", ammo: "Heavy", energy: "Stasis", slot: "Heavy", img: images.winterbite },
    { name: "wish_ender", display: "Wish Ender", type: "Bow", ammo: "Primary", energy: "Kinetic", slot: "Kinetic", img: images.wish_ender },
    { name: "witherhoard", display: "Witherhoard", type: "Grenade Launcher", ammo: "Special", energy: "Kinetic", slot: "Kinetic", img: images.witherhoard },
    { name: "worldline_zero", display: "Worldline Zero", type: "Sword", ammo: "Heavy", energy: "Arc", slot: "Heavy", img: images.worldline_zero },
    { name: "xenophage", display: "Xenophage", type: "Machine Gun", ammo: "Heavy", energy: "Solar", slot: "Heavy", img: images.xenophage },
  ],

  armors: [

    //hunter
    { name: "aeon_swift", display: "Aeon Swift", class: "hunter", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Gauntlets", img: images.aeon_swift },
    { name: "assassins_cowl", display: "Assassin's Cowl", class: "hunter", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Helmet", img: images.assassins_cowl },
    { name: "athryss_embrace", display: "Athrys's Embrace", class: "hunter", subclass: ["solar"], type: "Gauntlets", img: images.athryss_embrace },
    { name: "blight_ranger", display: "Blight Ranger", class: "hunter", subclass: ["arc"], type: "Helmet", img: images.blight_ranger },
    { name: "calibans_hand", display: "Caliban's Hand", class: "hunter", subclass: ["solar"], type: "Gauntlets", img: images.calibans_hand },
    { name: "celestial_nighthawk", display: "Celestial Nighthawk", class: "hunter", subclass: ["solar"], type: "Helmet", img: images.celestial_nighthawk },
    { name: "cyrtarachnes_facade", display: "Cyrtarachne's Facade", class: "hunter", subclass: ["strand"], type: "Helmet", img: images.cyrtarachnes_facade },
    { name: "foetracer", display: "Foetracer", class: "hunter", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Helmet", img: images.foetracer },
    { name: "fr0st_ee5", display: "Fr0st-EE5", class: "hunter", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Boots", img: images.fr0st_ee5 },
    { name: "gemini_jester", display: "Gemini Jester", class: "hunter", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Boots", img: images.gemini_jester },
    { name: "graviton_forfeit", display: "Graviton Forfeit", class: "hunter", subclass: ["void"], type: "Helmet", img: images.graviton_forfeit },
    { name: "gwisin_vest", display: "Gwisin Vest", class: "hunter", subclass: ["void"], type: "Chestpiece", img: images.gwisin_vest },
    { name: "gyrfalcons_hauberk", display: "Gyrfalcon's Hauberk", class: "hunter", subclass: ["void"], type: "Chestpiece", img: images.gyrfalcons_hauberk },
    { name: "khepris_sting", display: "Khepri's Sting", class: "hunter", subclass: ["void"], type: "Gauntlets", img: images.khepris_sting },
    { name: "knucklehead_radar", display: "Knucklehead Radar", class: "hunter", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Helmet", img: images.knucklehead_radar },
    { name: "liars_handshake", display: "Liar's Handshake", class: "hunter", subclass: ["arc"], type: "Gauntlets", img: images.liars_handshake },
    { name: "lucky_pants", display: "Lucky Pants", class: "hunter", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Boots", img: images.lucky_pants },
    { name: "lucky_raspberry", display: "Lucky Raspberry", class: "hunter", subclass: ["arc"], type: "Chestpiece", img: images.lucky_raspberry },
    { name: "mask_of_bakris", display: "Mask of Bakris", class: "hunter", subclass: ["stasis"], type: "Helmet", img: images.mask_of_bakris },
    { name: "mechaneers_tricksleeves", display: "Mechaneer's Tricksleeves", class: "hunter", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Gauntlets", img: images.mechaneers_tricksleeves },
    { name: "oathkeeper", display: "Oathkeeper", class: "hunter", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Gauntlets", img: images.oathkeeper },
    { name: "omnioculus", display: "Omnioculus", class: "hunter", subclass: ["void"], type: "Chestpiece", img: images.omnioculus },
    { name: "ophidia_spathe", display: "Ophidia Spathe", class: "hunter", subclass: ["solar"], type: "Chestpiece", img: images.ophidia_spathe },
    { name: "orpheus_rig", display: "Orpheus Rig", class: "hunter", subclass: ["void"], type: "Boots", img: images.orpheus_rig },
    { name: "radiant_dance_machines", display: "Radiant Dance Machines", class: "hunter", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Boots", img: images.radiant_dance_machines },
    { name: "raiden_flux", display: "Raiden Flux", class: "hunter", subclass: ["arc"], type: "Chestpiece", img: images.raiden_flux },
    { name: "raijus_harness", display: "Raiju's Harness", class: "hunter", subclass: ["arc"], type: "Chestpiece", img: images.raijus_harness },
    { name: "renewal_grasps", display: "Renewal Grasps", class: "hunter", subclass: ["stasis"], type: "Gauntlets", img: images.renewal_grasps },
    { name: "sealed_ahamkara_grasps", display: "Sealed Ahamkara Grasps", class: "hunter", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Gauntlets", img: images.sealed_ahamkara_grasps },
    { name: "shards_of_galanor", display: "Shards of Galanor", class: "hunter", subclass: ["solar"], type: "Gauntlets", img: images.shards_of_galanor },
    { name: "shinobus_vow", display: "Shinobu's Vow", class: "hunter", subclass: ["arc"], type: "Gauntlets", img: images.shinobus_vow },
    { name: "speedloader_slacks", display: "Speedloader Slacks", class: "hunter", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Boots", img: images.speedloader_slacks },
    { name: "st0mp_ee5", display: "St0mp-EE5", class: "hunter", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Boots", img: images.st0mp_ee5 },
    { name: "star_eater_scales", display: "Star-Eater Scales", class: "hunter", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Boots", img: images.star_eater_scales },
    { name: "the_bombardiers", display: "The Bombardiers", class: "hunter", subclass: ["void"], type: "Boots", img: images.the_bombardiers },
    { name: "the_dragons_shadow", display: "The Dragon's Shadow", class: "hunter", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Chestpiece", img: images.the_dragons_shadow },
    { name: "the_sixth_coyote", display: "The Sixth Coyote", class: "hunter", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Chestpiece", img: images.the_sixth_coyote },
    { name: "wormhusk_crown", display: "Wormhusk Crown", class: "hunter", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Helmet", img: images.wormhusk_crown },
    { name: "young_ahamkaras_spine", display: "Young Ahamkara's Spine", class: "hunter", subclass: ["solar"], type: "Gauntlets", img: images.young_ahamkaras_spine },

    //titan
    { name: "abeyant_leap", display: "Abeyant Leap", class: "titan", subclass: ["strand"], type: "Boots", img: images.abeyant_leap },
    { name: "acd0_feedback_fence", display: "ACD/0 Feedback Fence", class: "titan", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Gauntlets", img: images.acd0_feedback_fence },
    { name: "actium_war_rig", display: "Actium War Rig", class: "titan", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Chestpiece", img: images.actium_war_rig },
    { name: "aeon_safe", display: "Aeon Safe", class: "titan", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Gauntlets", img: images.aeon_safe },
    { name: "an_insurmountable_skullfort", display: "An Insurmountable Skullfort", class: "titan", subclass: ["arc"], type: "Helmet", img: images.an_insurmountable_skullfort },
    { name: "antaeus_wards", display: "Antaeus Wards", class: "titan", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Boots", img: images.antaeus_wards },
    { name: "armamentarium", display: "Armamentarium", class: "titan", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Chestpiece", img: images.armamentarium },
    { name: "ashen_wake", display: "Ashen Wake", class: "titan", subclass: ["solar"], type: "Gauntlets", img: images.ashen_wake },
    { name: "cadmus_ridge_lancecap", display: "Cadmus Ridge Lancecap", class: "titan", subclass: ["stasis"], type: "Helmet", img: images.cadmus_ridge_lancecap },
    { name: "citans_ramparts", display: "Citan's Ramparts", class: "titan", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Gauntlets", img: images.citans_ramparts },
    { name: "crest_of_alpha_lupi", display: "Crest of Alpha Lupi", class: "titan", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Chestpiece", img: images.crest_of_alpha_lupi },
    { name: "cuirass_of_the_falling_star", display: "Cuirass of the Falling Star", class: "titan", subclass: ["arc"], type: "Chestpiece", img: images.cuirass_of_the_falling_star },
    { name: "doom_fang_pauldron", display: "Doom Fang Pauldron", class: "titan", subclass: ["void"], type: "Gauntlets", img: images.doom_fang_pauldron },
    { name: "dunemarchers", display: "Dunemarchers", class: "titan", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Boots", img: images.dunemarchers },
    { name: "eternal_warrior", display: "Eternal Warrior", class: "titan", subclass: ["arc"], type: "Helmet", img: images.eternal_warrior },
    { name: "hallowfire_heart", display: "Hallowfire Heart", class: "titan", subclass: ["solar"], type: "Chestpiece", img: images.hallowfire_heart },
    { name: "heart_of_inmost_light", display: "Heart of Inmost Light", class: "titan", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Chestpiece", img: images.heart_of_inmost_light },
    { name: "helm_of_saint14", display: "Helm of Saint-14", class: "titan", subclass: ["void"], type: "Helmet", img: images.helm_of_saint14 },
    { name: "hoarfrost_z", display: "Hoarfrost-Z", class: "titan", subclass: ["stasis"], type: "Chestpiece", img: images.hoarfrost_z },
    { name: "icefall_mantle", display: "Icefall Mantle", class: "titan", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Gauntlets", img: images.icefall_mantle },
    { name: "khepris_horn", display: "Khepri's Horn", class: "titan", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Helmet", img: images.khepris_horn },
    { name: "lion_rampant", display: "Lion Rampant", class: "titan", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Boots", img: images.lion_rampant },
    { name: "loreley_splendor_helm", display: "Loreley Splendor Helm", class: "titan", subclass: ["solar"], type: "Helmet", img: images.loreley_splendor_helm },
    { name: "mask_of_the_quiet_one", display: "Mask of the Quiet One", class: "titan", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Helmet", img: images.mask_of_the_quiet_one },
    { name: "mk44_stand_asides", display: "Mk. 44 Stand Asides", class: "titan", subclass: ["arc", "solar", "void"], type: "Boots", img: images.mk44_stand_asides },
    { name: "no_backup_plans", display: "No Backup Plans", class: "titan", subclass: ["void"], type: "Gauntlets", img: images.no_backup_plans },
    { name: "one_eyed_mask", display: "One-Eyed Mask", class: "titan", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Helmet", img: images.one_eyed_mask },
    { name: "peacekeepers", display: "Peacekeepers", class: "titan", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Boots", img: images.peacekeepers },
    { name: "peregrine_greaves", display: "Peregrine Greaves", class: "titan", subclass: ["arc", "solar", "void"], type: "Boots", img: images.peregrine_greaves },
    { name: "phoenix_cradle", display: "Phoenix Cradle", class: "titan", subclass: ["solar"], type: "Boots", img: images.phoenix_cradle },
    { name: "point_cannon_contact_brace", display: "Point-Contact Cannon Brace", class: "titan", subclass: ["arc"], type: "Gauntlets", img: images.point_cannon_contact_brace },
    { name: "precious_scars", display: "Precious Scars", class: "titan", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Helmet", img: images.precious_scars },
    { name: "second_chance", display: "Second Chance", class: "titan", subclass: ["void"], type: "Gauntlets", img: images.second_chance },
    { name: "severance_enclosure", display: "Severance Enclosure", class: "titan", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Chestpiece", img: images.severance_enclosure },
    { name: "stronghold", display: "Stronghold", class: "titan", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Gauntlets", img: images.stronghold },
    { name: "synthoceps", display: "Synthoceps", class: "titan", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Gauntlets", img: images.synthoceps },
    { name: "the_path_of_burning_steps", display: "The Path of Burning Steps", class: "titan", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Boots", img: images.the_path_of_burning_steps },
    { name: "ursa_furiosa", display: "Ursa Furiosa", class: "titan", subclass: ["void"], type: "Gauntlets", img: images.ursa_furiosa },
    { name: "wormgod_caress", display: "Wormgod Caress", class: "titan", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Gauntlets", img: images.wormgod_caress },

    //warlock
    { name: "aeon_soul", display: "Aeon Soul", class: "warlock", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Gauntlets", img: images.aeon_soul },
    { name: "apotheosis_veil", display: "Apotheosis Veil", class: "warlock", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Helmet", img: images.apotheosis_veil },
    { name: "astrocyte_verse", display: "Astrocyte Verse", class: "warlock", subclass: ["void"], type: "Helmet", img: images.astrocyte_verse },
    { name: "ballidorse_wrathweavers", display: "Ballidorse Wrathweavers", class: "warlock", subclass: ["stasis"], type: "Gauntlets", img: images.ballidorse_wrathweavers },
    { name: "boots_of_the_assembler", display: "Boots of the Assembler", class: "warlock", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Boots", img: images.boots_of_the_assembler },
    { name: "chromatic_fire", display: "Chromatic Fire", class: "warlock", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Chestpiece", img: images.chromatic_fire },
    { name: "claws_of_ahamkara", display: "Claws of Ahamkara", class: "warlock", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Gauntlets", img: images.claws_of_ahamkara },
    { name: "contraverse_hold", display: "Contraverse Hold", class: "warlock", subclass: ["void"], type: "Gauntlets", img: images.contraverse_hold },
    { name: "crown_of_tempests", display: "Crown of Tempests", class: "warlock", subclass: ["arc"], type: "Helmet", img: images.crown_of_tempests },
    { name: "dawn_chorus", display: "Dawn Chorus", class: "warlock", subclass: ["solar"], type: "Helmet", img: images.dawn_chorus },
    { name: "eye_of_another_world", display: "Eye of Another World", class: "warlock", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Helmet", img: images.eye_of_another_world },
    { name: "fallen_sunstar", display: "Fallen Sunstar", class: "warlock", subclass: ["arc"], type: "Helmet", img: images.fallen_sunstar },
    { name: "felwinters_helm", display: "Felwinter's Helm", class: "warlock", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Helmet", img: images.felwinters_helm },
    { name: "geomag_stablizers", display: "Geomag Stablizers", class: "warlock", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Boots", img: images.geomag_stablizers },
    { name: "getaway_artist", display: "Getaway Artist", class: "warlock", subclass: ["arc"], type: "Gauntlets", img: images.getaway_artist },
    { name: "karnstein_armlets", display: "Karnstein Armlets", class: "warlock", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Gauntlets", img: images.karnstein_armlets },
    { name: "lunafaction_boots", display: "Lunafaction Boots", class: "warlock", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Boots", img: images.lunafaction_boots },
    { name: "mantle_of_battle_harmony", display: "Mantle of Battle Harmony", class: "warlock", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Chestpiece", img: images.mantle_of_battle_harmony },
    { name: "necrotic_grip", display: "Necrotic Grip", class: "warlock", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Gauntlets", img: images.necrotic_grip },
    { name: "nezarecs_sin", display: "Nezarec's Sin", class: "warlock", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Helmet", img: images.nezarecs_sin },
    { name: "nothing_manacles", display: "Nothing Manacles", class: "warlock", subclass: ["void"], type: "Gauntlets", img: images.nothing_manacles },
    { name: "ophidian_aspect", display: "Ophidian Aspect", class: "warlock", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Gauntlets", img: images.ophidian_aspect },
    { name: "osmiomancy_gloves", display: "Osmiomancy Gloves", class: "warlock", subclass: ["stasis"], type: "Gauntlets", img: images.osmiomancy_gloves },
    { name: "phoenix_protocol", display: "Phoenix Protocol", class: "warlock", subclass: ["solar"], type: "Chestpiece", img: images.phoenix_protocol },
    { name: "promethium_spur", display: "Promethium Spur", class: "warlock", subclass: ["solar"], type: "Boots", img: images.promethium_spur },
    { name: "rain_of_fire", display: "Rain of Fire", class: "warlock", subclass: ["solar"], type: "Boots", img: images.rain_of_fire },
    { name: "sanguine_alchemy", display: "Sanguine Alchemy", class: "warlock", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Chestpiece", img: images.sanguine_alchemy },
    { name: "secant_filaments", display: "Secant Filaments", class: "warlock", subclass: ["void"], type: "Boots", img: images.secant_filaments },
    { name: "skull_of_dire_ahamkara", display: "Skull of Dire Ahamkara", class: "warlock", subclass: ["void"], type: "Helmet", img: images.skull_of_dire_ahamkara },
    { name: "starfire_protocol", display: "Starfire Protocol", class: "warlock", subclass: ["solar"], type: "Chestpiece", img: images.starfire_protocol },
    { name: "stormdancers_brace", display: "Stormdancer's Brace", class: "warlock", subclass: ["arc"], type: "Chestpiece", img: images.stormdancers_brace },
    { name: "sunbracers", display: "Sunbracers", class: "warlock", subclass: ["solar"], type: "Gauntlets", img: images.sunbracers },
    { name: "swarmers", display: "Swarmers", class: "warlock", subclass: ["strand"], type: "Boots", img: images.swarmers },
    { name: "the_stag", display: "The Stag", class: "warlock", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Helmet", img: images.the_stag },
    { name: "transversive_steps", display: "Transversive Steps", class: "warlock", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Boots", img: images.transversive_steps },
    { name: "veritys_brow", display: "Verity's Brow", class: "warlock", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Helmet", img: images.veritys_brow },
    { name: "vesper_of_radius", display: "Vesper of Radius", class: "warlock", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Chestpiece", img: images.vesper_of_radius },
    { name: "wings_of_sacred_dawn", display: "Wings of Sacred Dawn", class: "warlock", subclass: ["solar"], type: "Chestpiece", img: images.wings_of_sacred_dawn },
    { name: "winters_guile", display: "Winter's Guile", class: "warlock", subclass: ["void", "solar", "arc", "stasis", "strand"], type: "Gauntlets", img: images.winters_guile },
  ]
}

export default data